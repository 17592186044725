<template>
  <div class="text-wrapper">
    <!-- If there's value prop set, use that instead of text from the slot -->
    <div v-if="$props.value">
      <span v-if="!textTooLong || (textTooLong && expanded)">{{$props.value}}</span>
      <span v-if="expanded" class="excerpt__read-more-or-less" @click="toggleExpansion">{{$props.readLessText}}</span>

      <span v-if="(textTooLong && !expanded)">{{excerpt}}</span>
      <span v-if="(textTooLong && !expanded)" class="excerpt__read-more-or-less" @click="toggleExpansion">{{$props.readMoreText}}</span>
    </div>

    <!-- If there's no value prop set, use text from the slot -->
    <div v-if="!$props.value">
      <slot v-if="!textTooLong || (textTooLong && expanded)"></slot>
      <span v-if="expanded" class="excerpt__read-more-or-less" @click="toggleExpansion">{{$props.readLessText}}</span>

      <span v-if="(textTooLong && !expanded)">{{excerpt}}</span>
      <span v-if="(textTooLong && !expanded)" class="excerpt__read-more-or-less" @click="toggleExpansion">{{$props.readMoreText}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    cutOffAtChar: {
      type: Number,
      default: 100,
    },
    readMoreText: {
      type: String,
      default: ' ...Read More',
    },
    readLessText: {
      type: String,
      default: ' ...Read Less',
    },
  },
  computed: {
    slotData() {
      return this.$slots.default[0].text;
    },
    textTooLong() {
      return this.$slots.default[0].text.length > this.$props.cutOffAtChar;
    },
    excerpt() {
      let fullText;
      if (this.$props.value) {
        fullText = this.$props.value;
      } else {
        fullText = this.$slots.default[0].text;
      }
      return fullText.length < this.$props.cutOffAtChar ?
        fullText :
        fullText.substring(0, this.$props.cutOffAtChar);
    },
  },
  methods: {
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">

.excerpt__read-more-or-less {
  font-weight: 550;
  cursor: pointer;
  font-style: italic;
  color: #0500ff55;
}
</style>
