<template>
    <div>
        <b-modal ref="modal-1" scrollable :title="title" v-model="showModal" :content-class="modalClass" header-class="text-center" >
            <vue-custom-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
                <div v-html="content"></div>
            </vue-custom-scrollbar>
            <div slot="modal-footer" class="w-100 text-center">
                <b-container fluid>
                    <b-row v-if="step == 1">
                        <b-col><b-button pill class="terms__button terms__button_transparent" @click="$emit('cancel-step-one')">Close</b-button></b-col>
                        <b-col><b-button pill class="terms__button terms__button_white" @click="$emit('accept-step-one')">Accept</b-button></b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col></b-col>
                        <b-col><b-button pill class="terms__button terms__button_white" @click="$emit('ok-step-two')">Shame On Me</b-button></b-col>
                    </b-row>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';


export default {
    props: ['value', 'title', 'content', 'type', 'step'],
    computed: {
        showModal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('modalToggle', value);
            }
        },
        modalClass() {
            return ['mj__modal_' + this.type, 'mj__modal'];
        }
    },
     components: {
    vueCustomScrollbar
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 300
      }
    }
  },
  methods: {
    scrollHanle(evt) {
      console.log(evt)
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 50vh;
}

.terms__button {
    width: 100%;
    border: solid 1px;
}

.terms__button_transparent {
    background-color: transparent;
    border-color: var(--white);
}

.terms__button_white {
    color: $black;
    background-color: var(--white);
}


</style>