<template>
  <div class="toggle">
    <!-- <div id="draggable_main" @mousedown.prevent="dragStart" :class="{ draggable_main : enabled }">
      <div class="draggable"  :style="style"></div>
    </div> -->
    <div class="ckbx-style-8 ckbx-xlarge">
      <input type="checkbox" id="ckbx-size-4" value="0" name="ckbx-square-1" />
      <label for="ckbx-size-4" @click.self="onClick"></label>
    </div>
    <div class="text">
      <small>{{ stateText }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 100,
      state: false,
      pressed: 0,
      position: 0
    };
  },
  mounted() {
    this.toggle(this.value);
  },
  computed: {
    style() {
      return {
        transform: `translateX(${this.pos_percentage})`
      };
    },
    pos_percentage() {
      return `${(this.position / this.width) * 100}%`;
    },
    state_class() {
      if (this.state) {
        return "active";
      }
    },
    stateText() {
      return this.state ? "ON" : "OFF";
    }
  },
  watch: {
    position() {
      this.state = this.position >= 50;
    }
  },
  methods: {
    onClick() {
      this.toggle(!this.state);
      this.emit();
    },
    toggle(state) {
      this.state = state;
      this.position = !state ? 0 : 100;
    },
    dragging(e) {
      const pos = e.clientX - this.$el.offsetLeft;
      const percent = (pos / this.width) * 100;
      this.position = percent <= 0 ? 0 : percent >= 100 ? 100 : percent;
    },
    dragStart(e) {
      this.startTimer();
      window.addEventListener("mousemove", this.dragging);
      window.addEventListener("mouseup", this.dragStop);
    },
    dragStop() {
      window.removeEventListener("mousemove", this.dragging);
      window.removeEventListener("mouseup", this.dragStop);
      this.resolvePosition();
      clearInterval(this.$options.interval);
      if (this.pressed < 30) {
        this.toggle(!this.state);
      }
      this.pressed = 0;
      this.emit();
    },
    startTimer() {
      this.$options.interval = setInterval(() => {
        this.pressed++;
      }, 1);
    },
    resolvePosition() {
      this.position = this.state ? 100 : 0;
    },
    emit() {
      this.$emit("input", this.state);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/checkboxes.min.css";

.toggle {
  width: 100px;
  height: 50px;
}
.ckbx-style-8 label:before {
  background: #fff;
  width: 100px;
  height: 50px;
  box-shadow: none !important;
      border: 1px solid #0500ff;
      z-index: 900;
}
.ckbx-style-8 label, .text, .main__heading{
  z-index: 900;
}

.ckbx-style-8 input[type="checkbox"]:checked + label:before {
  background: #0500ff;
}
.ckbx-style-8 input[type=checkbox]:checked+label:after{
  left: 1.4em;
}

.ckbx-style-8 label:after {
  -webkit-animation: 4s pulse infinite;
  animation: 4s pulse infinite;
  width: 50px;
  height: 50px;
  background:  #fff !important;
  z-index: 999;
}
.toggle #draggable_main {
  cursor: pointer;
}

.toggle .text {
  color: #0500ff;
  text-align: center;
  text-transform: uppercase;
}
.toggle .text small {
  font-weight: bold !important;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(0.88);
    transform: scale(0.88);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(0.88);
    transform: scale(0.88);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
</style>
