<template>
  <div class="main__window" id="Main">
    <b-container fluid>
      <!-- This and below is the terms window commented out to not show on the screen --> 
      <!-- :class="{ main__blurry_background: step1Terms }"> -->
      <b-row>
        <b-col cols="0" sm="0" md="0" lg="1" xl="2">
        </b-col>
        <b-col cols="7" sm="8" md="9" lg="7" xl="6">
          <h1 class="main__heading">{{ title }}</h1>
        </b-col>
        <b-col cols="5" sm="4" md="3" lg="3" xl="2" class="toggle-center" >
          <toggle
            v-if="effectsJson.toggle"
            :enabled="effectsJson.toggle.enabled"
            v-model="toggle"
          />
        </b-col>
        <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
      </b-row>
      <b-row>
        <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
        <b-col cols="12" sm="12" md="9" lg="7" xl="6">
          <div :class="{ main__content: true }">
            <div
              id="main__content"
              v-html="text"
            />
            <div class="text-center">
              <img src="@/assets/images/toggle-button.png">
            </div>
          </div>
          <div class="text-center">
            <b-button
              :to="{ name: 'register' }"
              variant="primary"
              class="custom-btn px-4 mb-5"
              pill
            >...and SIGN UP for more information
            </b-button>
          </div>
          <div class="main__blurry_bottom"></div>
        </b-col>
        <b-col cols="12" sm="12" md="3" lg="3" xl="2">
          <p
            class="text-xm-left main__annotation"
            v-for="(annotation, index) in annotations"
            :key="annotation.length"
          >
            <small>
              <excerpt>{{ index + 1 }}. {{ annotation }}</excerpt>
            </small>
          </p>
        </b-col>
        <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>        
      </b-row>
    </b-container>
    <!-- This is the terms window commented out to not show on the screen--> 
    <!-- <terms
      v-model="step1Terms"
      step="1"
      :title="step1.title"
      :content="step1.body"
      :type="step1.type"
      @cancel-step-one="onCancelStepOne"
      @accept-step-one="onAcceptStepOne"
      @modalToggle="value => (step1Terms = value)"
    ></terms>

    <terms
      v-model="step2Terms"
      step="2"
      :title="step2.title"
      :content="step2.body"
      :type="step2.type"
      @ok-step-two="step2Terms = false"
      @modalToggle="value => (step2Terms = value)"
    ></terms> -->
  </div>
</template>

<script>
// utils
import store from "@/store.js";
import { windowHeight, bottomHeight } from "@/util/web.js";

// json
import GetJson from "@/repository/json/GetJson.js";
const contentJson = GetJson("MainContent", "eng");
const termsJson = GetJson("MainTerms", "eng");
const effectsJson = GetJson("Effects");

// components
import Excerpt from "@/components/main/Excerpt.vue";
import Terms from "@/components/main/Terms.vue";
import Toggle from "@/components/main/ToggleButton.vue";

export default {
  name: "Main",
  created() {
    document.body.className = "";
    const { title, body, type } = this.termsJson.violet;
    Object.assign(this.step1, { title, body, type });
    this.handleTerms()
    document.addEventListener("wheel", this.onScroll);
  },
  mounted() {
    // Handle "watch" for state change in appMounted
    setTimeout(() => {
      while (!this.state.appMounted) {}
      this.handleBottomBlur(true);
    }, 100);
  },
  data() {
    return {
      toggle: false,
      state: store.state,
      step1Terms: false,
      step2Terms: false,
      startMillis: -1,
      step1: {},
      step2: {},
      mainJson: contentJson,
      termsJson: termsJson,
      effectsJson: effectsJson
    };
  },
  computed: {
    title() {
      return this.mainJson.title;
    },
    annotations() {
      return this.mainJson.annotations;
    },
    fullText() {
      return this.mainJson.content;
    },
    shortText() {
      const replacement = 'class="mj__missing"';
      return this.fullText.replace(/name=\"missing\"/gi, replacement);
    },
    text() {
      return this.toggle ? this.shortText : this.fullText;
    }
  },
  watch: {
    step1Terms(val) {
      this.startMillis = val ? Date.now() : -1;
    }
  },
  methods: {
    handleTerms() {
      this.step1Terms = !this.$cookie.get("terms");
    },

    handleBottomBlur(init) {
      const footerHeight = this.state.footerHeight;
      const main_content_blur = document.getElementById("main__content");

      if (init) {
        const blur =
          windowHeight() - footerHeight < bottomHeight() ? false : true;
        store.setBlur(blur);
      } else {
        if (
          window.innerHeight + window.scrollY >=
          main_content_blur.offsetHeight + 150
        ) {
          store.setBlur(false);
        } else store.setBlur(true);
      }
    },

    onScroll() {
      // this.handleBottomBlur();
    },

    onCancelStepOne() {
      this.step1Terms = false;
    },

    onAcceptStepOne() {
      this.$cookie.set("terms", true, 1);
      const secondsDiff = (Date.now() - this.startMillis) / 1000;
      this.step1Terms = false;

      const { title, body, type } =
        secondsDiff < this.termsJson.green.seconds
          ? this.termsJson.red
          : secondsDiff < this.termsJson.yellow.seconds
          ? this.termsJson.yellow
          : this.termsJson.green;
      Object.assign(this.step2, { title, body, type });
      this.step2Terms = true;
    }
  },

  components: {
    Excerpt,
    Terms,
    Toggle
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


.main__window {
  min-height: 100vh;
}

.main__heading {
  color: $blue;
  text-transform: uppercase;
  font-family: "Good Sans Regular";
}

.main__content {
  font-size: $content-font-size;
  font-family: "Good Sans Regular";
  color: #000;
  padding-bottom: 50px;
  position: relative;

}

.main__annotation {
  color: $blue;
  padding-bottom: $layout-padding-px;
}

.main__blurry_bottom {
 position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: -webkit-linear-gradient(rgba(204,218,232, 0) 0%, rgba(204,218,232, 0.9) 95%);
    background-image: -ms-linear-gradient(rgba(204,218,232, 0) 0%, rgba(204,218,232, 0.9) 95%);
    background-image: linear-gradient(rgba(204,218,232, 0) 0%, rgba(204,218,232, 0.9) 95%);
    background-image: -moz-linear-gradient(rgba(204,218,232, 0) 0%, rgba(204,218,232, 0.9) 95%);
    background-image: -o-linear-gradient(rgba(204,218,232, 0) 0%, rgba(204,218,232, 0.9) 95%);
    pointer-events: none;
}

.main__blurry_background {
  filter: blur(5px);
}

.row {
  padding-top: 50px !important;
}

.text-wrapper {
  font-family: "Roboto Regular";
}

.toggle-center {
  display: flex;
  // align-items: center;
  justify-content: center;
}

.custom-btn {
  font-size: $content-font-size;
}

img {
  height: 50px;
}

</style>
