<template>
    <div class="home">
        <mj-main />
    </div>
</template>

<script>
    // @ is an alias to /src
    import Main from "@/components/main/Main.vue";

    export default {
        name: "mainHome",
        components: {
            mjMain: Main
        }
    };
</script>

<style lang="scss" scoped>
    .home
    {
        background-color: #ccdae8;
    }
</style>
